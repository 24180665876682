/* You can add global styles to this file, and also import other style files */
.fixed-footer {
    display: block !important; 
    position: inherit;
    left: 0;
    bottom: 0;
    width: 100%;
}

app-root {
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.app c-main-content > section, c-main-content.app > section {
    padding: 15px !important;
}